/* eslint-disable */
import { handleRequest } from 'networking/requests';
import { ENDPOINTS } from '../endpoints';
import { PersonType } from 'types/person';

const updateProfile = async (data: Partial<PersonType>) => {
  return handleRequest({
    method: 'PATCH',
    endpoint: ENDPOINTS.PERSON.UPDATE,
    data,
    handleResponse: () => {}
  });
};

export const update = {
  profile: updateProfile
};
