/* eslint-disable */
// general react imports
import { Dispatch } from 'react';
import { LatLng, latLng } from 'leaflet';
import _ from 'lodash';

// project specific files
import { set_offers_payload } from 'redux/slicers/offers_slicer';
// import { store } from "redux/store";
import { set_people_content } from 'redux/slicers/people_slicer';
import { PhotoType } from 'types/photo.d';
import { ModuleDataType } from 'types/data.d';
import { PersonType } from 'types/person.d';
import { set_places_content } from 'redux/slicers/places_slicer';
import { PlaceType } from 'types/place.d';
import { MarkerType, set_markers_content } from 'redux/slicers/markers_slicer';
import { print } from 'functions/print';
import { Language } from 'types/setting';
import { set_closest_place } from 'redux/slicers/map_slicer';
import { appEnv } from 'app_env';
import { activities } from 'payloads/activities';
import { handleRequest } from 'networking/requests';
import { ENDPOINTS } from '../endpoints';

const getPlacesFromApiById = async (dispatch: Dispatch<any>, place_id: string) => {
  return handleRequest({
    endpoint: ENDPOINTS.PLACE.LIST,
    params: { p: place_id },
    handleResponse: (response: any) => {
      const payload: MarkerType[] = response.data.results.map((element: any) => {
        return {
          label: element.name,
          coordinates: {
            latitude: element.location[0],
            longitude: element.location[1]
          },
          short: element.short
        };
      });
      // save places array to markers store
      dispatch(set_markers_content(payload));
    }
  });
};

const getPlacesFromApiByRange = async (
  dispatch: Dispatch<any>,
  place_id: string,
  range: number
) => {
  return handleRequest({
    endpoint: `${ENDPOINTS.PLACE.LIST}/${place_id}/range`,
    params: { d: range },
    handleResponse: (response: any) => {
      const payload: MarkerType[] = response.data.results.map((element: any) => {
        return {
          id: element.id,
          label: element.name,
          coordinates: new LatLng(element.location[0], element.location[1]),
          short: element.short
        };
      });
      // save places array to markers store
      dispatch(set_markers_content(payload));
    }
  });
};

const getPeopleFromApi = async (
  dispatch: Dispatch<any>,
  place_id: string,
  encoded_query: string,
  language_query: string,
  activity_query: string
) => {
  return handleRequest({
    endpoint: ENDPOINTS.PERSON.LIST,
    params: _.omitBy(
      {
        p: place_id,
        q: encoded_query,
        a: activity_query,
        l: language_query
      },
      _.isEmpty
    ),
    handleResponse: (response: any) => {
      const payload = response.data.results.map(
        (element: {
          activity: Array<number>;
          assigned_places: Array<any>;
          name: string;
          photo: Array<any>;
          query_score: number;
          short: string;
        }) => ({
          label: element.name,
          photo: element.photo,
          id: element.short,
          activities: element.activity.map((activity) => activities[activity - 1])
        })
      );
      dispatch(
        set_offers_payload({
          payload,
          filter_favourites: false
        })
      );
    }
  });
};

const getSinglePersonFromApi = async (dispatch: Dispatch<any>, short_code: string) =>
  handleRequest({
    endpoint: `${ENDPOINTS.PERSON.DETAIL}${short_code}`,
    handleResponse: (response: any) => {
      let data = response.data;
      let payload: PersonType = {
        id: data.id,
        name: data.name,
        time: data.time,
        photos: data.photo.map(
          (photo: any): PhotoType => ({
            original: photo.link,
            thumbnail: photo.link,
            is_title_shot: photo.is_title_shot
          })
        ),
        activity: data.activity.map((activity: string): ModuleDataType => {
          return { label: activity };
        }),
        coordinates: latLng(0, 0),
        verified: data.verified,
        website: [`${appEnv.url.frontend}/${data.short}`, ...data.website],
        age: [{ label: data.age }],
        body_type: [{ label: data.body_type }],
        cup_size: [{ label: data.cup_size }],
        penis_size: [{ label: data.penis_size }],
        ethnicity: [{ label: data.ethnicity }],
        i_am: [{ label: data.i_am }],
        looking_for: data.looking_for.map((x: string) => ({ label: x })),
        hair: [{ label: data.hair }],
        height: [{ label: data.height }],
        language: data.language.map((language: string) => ({ label: language }))
      };
      dispatch(set_people_content(payload));
    }
  });

const getSinglePlaceFromApi = async (dispatch: Dispatch<any>, id: string) =>
  handleRequest({
    endpoint: ENDPOINTS.PLACE.DETAIL,
    id: id,
    handleResponse: (response: any) => {
      let data = response.data;
      let payload: PlaceType = {
        id: data.id,
        name: data.name,
        type: data.type,
        coordinates: latLng(data.location[0], data.location[1]),
        verified: data.verified,
        website: data.webpage,
        photos: data.photo,
        short: data.short,

        people: data.assigned.map((person: any) => {
          return {
            label: person.name,
            photo: person.photo,
            id: person.pk,
            activities: person.activities.map((activity: string) => {
              return { label: activity };
            })
          };
        }),
        activity: data.activities.map((activity: string) => {
          return { label: activity };
        }),
        // TODO UNAVAILABLE IN API YET
        time: [],
        age: [],
        body_type: [],
        cup_size: [],
        penis_size: [],
        ethnicity: [],
        i_am: [],
        looking_for: [],
        hair: [],
        height: [],
        language: []
      };
      // save place to store
      dispatch(set_places_content({ payload: payload }));
    }
  });

const getClosestPlaceIdFromApi = async (dispatch: Dispatch<any>, coordinates: LatLng) => {
  return handleRequest({
    endpoint: ENDPOINTS.PLACE.CLOSEST,
    params: { lat: coordinates.lat.toPrecision(4), lng: coordinates.lng.toPrecision(4) },
    handleResponse: (response: any) => {
      // save place to store
      dispatch(
        set_closest_place({
          id: response.data.id,
          name: response.data.name,
          short: response.data.short
        })
      );
    }
  });
};

const getLawFromApi = async (_dispatch: Dispatch<any>) => {
  return handleRequest({
    endpoint: ENDPOINTS.LAW,
    handleResponse: (response: any) => print.info(response)
  });
};

const getLanguagesFromApi = async (_dispatch: Dispatch<any>) => {
  return handleRequest({
    endpoint: ENDPOINTS.LANGUAGE,
    handleResponse: (response: any) => print.info(response)
  });
};

const getSingleLanguageFromApi = async (_dispatch: Dispatch<any>, code: Language['code']) => {
  return handleRequest({
    endpoint: ENDPOINTS.LANGUAGE,
    params: code,
    handleResponse: (response: any) => print.info(response)
  });
};

const getMailFromApi = async (_dispatch: Dispatch<any>) => {
  // const query = store.getState().settings;

  return handleRequest({
    endpoint: ENDPOINTS.MAIL,
    handleResponse: (response: any) => print.info(response)
  });
};

export const get = {
  single: {
    person: getSinglePersonFromApi,
    place: {
      by: { id: getSinglePlaceFromApi, distance: { closest: getClosestPlaceIdFromApi } }
    },
    language: getSingleLanguageFromApi
  },
  multiple: {
    language: getLanguagesFromApi,
    law: getLawFromApi,
    mail: getMailFromApi,
    person: getPeopleFromApi,
    place: {
      by: { id: getPlacesFromApiById, distance: getPlacesFromApiByRange }
    }
  }
};
