export const dict_en = {
  'Local law: GB': 'Local law: GB',
  EULA: 'EULA',
  'Privacy Policy': 'Privacy Policy',
  'Terms & Conditions': 'Terms & Conditions',
  Next: 'Next',
  ACCEPT: 'ACCEPT',
  DECLINE: 'DECLINE',
  Close: 'Close',
  Search: 'Search',
  Body: 'Body',
  General: 'General',
  'See more dates': 'See more dates',
  Summary: 'Summary',
  About: 'About',
  'API Docs': 'API Docs',
  Stats: 'Stats',
  'Priluvia NOW': 'Priluvia NOW',
  'Buy Premium Access': 'Buy Premium Access',
  NOW: 'NOW',
  'Log in': 'Log in',
  Mail: 'Mail',
  Photos: 'Photos',
  'Assigned places': 'Assigned places',
  'Add new place': 'Add new place',

  SETTINGS: 'SETTINGS',
  ABOUT: 'ABOUT',
  'CHECK FOR APP UPDATE': 'CHECK FOR APP UPDATE',
  'Check for app update': 'Check for app update',
  Priluvia: 'Priluvia',
  'PRILUVIA IS A FREE SEARCH ENGINE': 'PRILUVIA IS A FREE SEARCH ENGINE',
  'THAT CONNECTS YOU': 'THAT CONNECTS YOU',
  'WITH PEOPLE AND PLACES': 'WITH PEOPLE AND PLACES',
  'PRIVACY POLICY': 'PRIVACY POLICY',
  CONTACT: 'CONTACT',
  Contact: 'Contact',
  EDUCATION: 'EDUCATION',
  Education: 'Education',
  'API DOCS': 'API DOCS'
};
