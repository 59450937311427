// general react imports
import { createSlice } from '@reduxjs/toolkit';
import { latLng } from 'leaflet';

// types
import type { PayloadAction } from '@reduxjs/toolkit';
import { PersonType } from 'types/person.d';

const initialState: PersonType = {
  id: '1',
  name: 'Grażyna',
  time: [
    {
      label: '9:30'
    }
  ],
  coordinates: latLng(52.3724, 4.8977),
  verified: true,
  website: ['https://www.google.pl/', 'https://onlyfans.com/'],
  activity: [{ label: 'handjob' }, { label: 'oral' }, { label: 'vaginal' }],
  age: [{ label: 27 }],
  body_type: [{ label: 'thicc' }],
  cup_size: [{ label: 'C' }],
  ethnicity: [{ label: 'white' }],
  i_am: [{ label: 'female' }],
  looking_for: [{ label: 'male' }, { label: 'female' }],
  hair: [{ label: 'black' }],
  height: [{ label: 174 }],
  language: [{ label: 'english' }, { label: 'polski' }],
  penis_size: [],
  photos: [
    {
      original: 'https://picsum.photos/id/1018/1000/600/',
      thumbnail: 'https://picsum.photos/id/1018/250/150/',
      is_title_shot: true
    },
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
      is_title_shot: false
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
      is_title_shot: false
    }
  ]
};

export const peopleSlice = createSlice({
  name: 'people',
  initialState: {
    payload: initialState
  },
  reducers: {
    set_people_content: (state, action: PayloadAction<PersonType>) => {
      state.payload = { ...state.payload, ...action.payload };
    }
  }
});

export const { set_people_content } = peopleSlice.actions;
export const peopleReducer = peopleSlice.reducer;
