// general react imports
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// project specific imports
import { MailBoxStateType, MailType, MailTypeType } from 'types/mail.d';
import { appEnv } from 'app_env';

const initialState: MailBoxStateType = {
  mails: {
    0: {
      id: '0',
      message: `Welcome to ${appEnv.name}!`,
      type: MailTypeType.SYSTEM,
      creation_date: String(new Date()),
      due_date: String(new Date()),
      title: 'Welcome!',
      subtitle: `${appEnv.name} Team`,
      img: '/logo192.png',
      is_read: false
    },
    1: {
      id: '1',
      message: '',
      type: MailTypeType.SYSTEM,
      creation_date: String(new Date()),
      due_date: String(new Date()),
      title: 'Registration completed!',
      subtitle: `at ${String(new Date())}`,
      img: '',
      is_read: false
    }
  },
  last_update: ''
};

export const mailSlice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    set_mail: (state, action: PayloadAction<MailType>) => {
      state.mails[action.payload.id] = action.payload;
    },
    delete_mail: (state, action: PayloadAction<MailType>) => {
      const mailbox_copy = { ...state.mails };
      delete mailbox_copy[action.payload.id];
      state.mails = mailbox_copy;
    }
  }
});

export const { set_mail, delete_mail } = mailSlice.actions;
export const mailReducer = mailSlice.reducer;
