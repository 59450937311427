// general react imports
import { createSlice } from '@reduxjs/toolkit';
import { LatLng, latLng } from 'leaflet';

// types
import type { PayloadAction } from '@reduxjs/toolkit';
import { ModuleDataTypeArray } from 'types/data.d';
import {
  Activity,
  BodyType,
  CupSize,
  Ethnicity,
  GenderArray,
  Hair,
  LookingFor,
  PersonType
} from 'types/person.d';
import { PhotoType } from 'types/photo.d';

const initialState: PersonType = {
  id: '',
  name: 'Your Name',
  time: [
    {
      label: '9:30'
    }
  ],
  coordinates: latLng(52.3724, 4.8977),
  visibility: false,
  verified: false,
  phone: '',
  email: '',
  website: [],
  activity: [{ label: 'handjob' }, { label: 'oral' }, { label: 'vaginal' }],
  age: [{ label: 27 }],
  body_type: [{ label: 'thicc' }],
  cup_size: [{ label: 'C' }],
  ethnicity: [{ label: 'white' }],
  i_am: [{ label: 'female' }],
  looking_for: [{ label: 'male' }, { label: 'female' }],
  hair: [{ label: 'black' }],
  height: [{ label: 174 }],
  language: [{ label: 'english' }, { label: 'polish' }],
  penis_size: [{ label: '12' }],
  photos: [
    {
      original: 'https://picsum.photos/id/1018/1000/600/',
      thumbnail: 'https://picsum.photos/id/1018/250/150/',
      is_title_shot: true
    },
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
      is_title_shot: false
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
      is_title_shot: false
    }
  ]
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    set_profile_name: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    set_profile_coordinates: (state, action: PayloadAction<LatLng>) => {
      state.coordinates = action.payload;
    },
    set_profile_visibility: (state, action: PayloadAction<boolean>) => {
      state.visibility = action.payload;
    },
    set_profile_verified: (state, action: PayloadAction<boolean>) => {
      state.verified = action.payload;
    },
    set_profile_phone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    set_profile_email: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    set_profile_website: (state, action: PayloadAction<Array<string>>) => {
      state.website = action.payload;
    },
    set_profile_activity: (state, action: PayloadAction<Activity>) => {
      state.activity = action.payload;
    },
    set_profile_age: (state, action: PayloadAction<ModuleDataTypeArray>) => {
      state.age = action.payload;
    },
    set_profile_body_type: (state, action: PayloadAction<BodyType>) => {
      state.body_type = action.payload;
    },
    set_profile_cup_size: (state, action: PayloadAction<CupSize>) => {
      state.cup_size = action.payload;
    },
    set_profile_ethnicity: (state, action: PayloadAction<Ethnicity>) => {
      state.ethnicity = action.payload;
    },
    set_profile_i_am: (state, action: PayloadAction<GenderArray>) => {
      state.i_am = action.payload;
    },
    set_profile_looking_for: (state, action: PayloadAction<LookingFor>) => {
      state.looking_for = action.payload;
    },
    set_profile_hair: (state, action: PayloadAction<Hair>) => {
      state.hair = action.payload;
    },
    set_profile_height: (state, action: PayloadAction<ModuleDataTypeArray>) => {
      state.height = action.payload;
    },
    set_profile_language: (state, action: PayloadAction<ModuleDataTypeArray>) => {
      state.language = action.payload;
    },
    set_profile_penis_size: (state, action: PayloadAction<ModuleDataTypeArray>) => {
      state.penis_size = action.payload;
    },
    set_profile_photos: (state, action: PayloadAction<Array<PhotoType>>) => {
      state.photos = action.payload;
    },
    set_profile_content: (state, action: PayloadAction<PersonType>) => {
      state = { ...state, ...action.payload };
    }
  }
});

export const {
  set_profile_name,
  set_profile_coordinates,
  set_profile_visibility,
  set_profile_verified,
  set_profile_phone,
  set_profile_email,
  set_profile_website,
  set_profile_activity,
  set_profile_age,
  set_profile_body_type,
  set_profile_cup_size,
  set_profile_ethnicity,
  set_profile_i_am,
  set_profile_looking_for,
  set_profile_hair,
  set_profile_height,
  set_profile_language,
  set_profile_penis_size,
  set_profile_photos,
  set_profile_content
} = profileSlice.actions;

export const profileReducer = profileSlice.reducer;
