// general react imports
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Box } from '@mui/material';

// project specific files

import { LegalPanel } from 'components/legal/legal_panel';
import { IllegalCountryDialog } from 'components/legal/illegal_country_dialog';
import { LogicController } from 'logic_controller';
import { PanelContent } from './panel_content';
import { ErrorComponentIcon } from 'error/error_component_icon';
import { isBot } from 'networking/seo/bot_detection/botDetection';
import { AnnouncementPanel } from 'components/announcement/announcement_panel';

export const Panel = ({
  headerText,
  children,
  hideHeader,
  hidePanel = false,
  disableLegal = false
}: {
  headerText?: string;
  children?: ReactNode;
  hideHeader?: boolean;
  hidePanel?: boolean;
  disableLegal?: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <LogicController />
      {(!disableLegal || isBot) && <LegalPanel />}
      <IllegalCountryDialog />
      <AnnouncementPanel />
      {hidePanel ? (
        children
      ) : (
        <Box
          sx={{
            display: children ? 'flex' : 'none',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            zIndex: '11',
            width: '100%',
            height: '100%'
          }}
          onClick={() => navigate(-1)}
        >
          <Box
            className="panel gradient"
            sx={{
              maxWidth: '600px',
              width: '100%',
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              bgcolor: 'background.paper',
              overflow: 'hidden',
              borderRadius: '12px',
              boxShadow: 5,
              fontWeight: 'bold',
              padding: '10px 30px 20px 30px',
              zIndex: '12'
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ErrorBoundary fallback={<ErrorComponentIcon />}>
              <PanelContent headerText={headerText} hideHeader={hideHeader}>
                {children}
              </PanelContent>
            </ErrorBoundary>
          </Box>
        </Box>
      )}
    </div>
  );
};
