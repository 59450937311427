// general react imports
import React from 'react';
import { persistor, store } from 'redux/store';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from 'service-worker/reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// project specific files
import { AppUpdate } from 'service-worker/app_update';
import { print } from 'functions/print';
import { App } from 'app';

// css files
import 'styles/index.css';
import 'styles/map.css';
import { appEnv } from 'app_env';

// fix "console has no warning attribute" typescript error
declare global {
  interface Console {
    warning: () => void;
  }
}

const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppUpdate />
      <App />
    </PersistGate>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(print.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (appEnv.env === 'development') {
  reportWebVitals(print.info);
}
