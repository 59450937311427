export const ENDPOINTS = {
  ACTIVITY: 'api/activity',
  ADMIN: 'admin',
  COUNTRY: 'api/country',
  EVENT: 'api/event',
  LANGUAGE: 'api/language',
  LAW: 'api/law',
  MAIL: 'api/mail',
  PERSON: { LIST: 'api/person', DETAIL: '', UPDATE: 'api/person/update_person/' },
  PHOTO: 'api/photo',
  PLACE: { LIST: 'api/place', CLOSEST: 'api/place/closest', DETAIL: '' },
  REPORT: {
    PLACE: 'api/report/place',
    PERSON: 'api/report/person'
  },
  USER: {
    LOGIN: 'api/user/login',
    LOGOUT: 'api/user/logout',
    REGISTER: 'api/user/register',
    TOKEN: 'api/user/csrf-token'
  },
  WEBSITE: 'api/website'
};
