// general react imports
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from 'i18next';

// project specific files
import { SettingState } from 'types/setting.d';
import { showIllegalDialog } from 'components/legal/illegal_country_dialog';
import { getDeviceCountry } from 'countries/countries_support';
import { set_country_content, set_language_content } from 'redux/slicers/settings_slicer';
import { getDeviceLanguageObject } from 'languages/language_support';
import { isBot } from 'networking/seo/bot_detection/botDetection';
import { account } from 'networking/rest/accounts';

export const LogicController = () => {
  const dispatch = useDispatch();
  const country = useSelector(
    (state: { [key: string]: SettingState }) => state['settings']!.country[0]
  );
  const language = useSelector(
    (state: { [key: string]: SettingState }) => state['settings']!.language[0]
  );

  // starting country and languages
  useEffect(() => {
    // get device country
    const device_country = getDeviceCountry();
    if (device_country) {
      dispatch(set_country_content([device_country]));
    }

    // get device language
    const device_laguage = getDeviceLanguageObject();
    if (device_laguage) {
      dispatch(set_language_content([device_laguage]));
    }

    // get token from api to cookie
    account.token();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // languages update
  useEffect(() => {
    // set selected language
    changeLanguage(language!.code);
  }, [language]);

  // prevent saving illegal country
  useEffect(() => {
    if (country!.legal === 'no' && !isBot) {
      // show illegality popup
      showIllegalDialog(dispatch, country!.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return <></>;
};
