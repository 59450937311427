// general react imports
import { createSlice } from '@reduxjs/toolkit';

// types
import type { PayloadAction } from '@reduxjs/toolkit';
import { ModuleDataTypeArray } from 'types/data.d';
import { SearchState } from 'types/search.d';

const initialState: SearchState = {
  content: [{ label: 'Search Location' }]
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    set_search_content: (state, action: PayloadAction<ModuleDataTypeArray>) => {
      state.content = action.payload;
    }
  }
});

export const { set_search_content } = searchSlice.actions;

export const searchReducer = searchSlice.reducer;
