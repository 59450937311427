// general react imports
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// project specific files
import { ModuleDialog } from 'components/containers/module_dialog';
import { AnnouncementState, hide_announcement } from 'redux/slicers/announcement_slicer';
import { ModuleCarousel } from 'components/containers/module_carousel';
import { AdDontPrepay } from './ads/dont_prepay_ad';
import { AdWhyWatch } from './ads/why_watch_ad';
import { LoadingIndicator } from 'components/loading/loading_indicator';
import { AdWhyDate } from './ads/why_date_ad';
import { AdUseCash } from './ads/use_cash_ad';

export const AnnouncementPanel = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const visibility = useSelector((state: { [key: string]: AnnouncementState }) => {
    return state['announcement']!.visibility;
  });
  const handleClose = () => {
    dispatch(hide_announcement());
  };

  // enable loading on ad popup
  useEffect(() => {
    setIsLoading(true);
  }, [visibility]);

  return (
    <ModuleDialog title={'Announcement'} is_opened={visibility} handleClose={handleClose}>
      <ModuleCarousel pagination>
        <AdDontPrepay />
        <AdUseCash />
        <AdWhyWatch />
        <AdWhyDate />
        <LoadingIndicator isLoading={isLoading} />
      </ModuleCarousel>
    </ModuleDialog>
  );
};
