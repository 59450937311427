// general react imports

export enum MailTypeType {
  SYSTEM = 'system',
  EVENT_START = 'event_start',
  EVENT_CREATE = 'event_create',
  EVENT_UPDATE = 'event_update',
  EVENT_DELETE = 'event_delete',
  PLACE = 'place',
  PERSON = 'person'
}

export interface MailType {
  id: string;
  title: string;
  subtitle: string;
  img?: string;
  message?: string;
  type: MailTypeType;
  creation_date: string;
  due_date: string;
  is_read: boolean;
}

export type MailBoxType = {
  [key: string]: MailType;
};

export type MailBoxStateType = {
  mails: MailBoxType;
  last_update: string;
};
