// general react imports
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { disableBodyScroll } from 'body-scroll-lock';

// project specific files
import { useAppSelector } from 'redux/hooks';
import { ThemePalette } from 'styles/theme_palette';
import { RoutesController } from 'routes_controller';
import { ToastController } from 'components/toast/toast';
import { NetworkStatus } from 'networking/network_status';
import { initLanguages } from 'languages/translateText';

// css files
import 'styles/button.css';
import 'styles/containers.css';
import 'styles/chip.css';
import 'styles/grid.css';
import 'styles/icon.css';
import 'styles/input.css';
import 'styles/progress.css';
import 'styles/searchbar.css';
import 'styles/scheduler.css';
import 'styles/scrollbar.css';
import 'styles/swiper.css';
import 'styles/table.css';
import 'styles/toast.css';

// theme
import 'styles/neon.css';

// init localization languages
initLanguages();

// init color modes
const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

// setup app
export const App = () => {
  disableBodyScroll(document.body);
  window.scrollTo(0, 1);

  // color themes
  const mode = useAppSelector((state) => state.settings.mode);
  const colorMode = React.useContext(ColorModeContext);
  const theme = React.useMemo(() => ThemePalette(mode), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <NetworkStatus />
        <ToastController />
        <RouterProvider router={RoutesController} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
