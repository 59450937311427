// get content of .env file
type environment = 'development' | 'production';

/**
 * Age rating
 * You can use on of the following values:
 * general | mature | restricted | adult | 14 years | safe for kids
 */
export const appEnv: {
  name: string;
  description: string;
  faceBookAppID: string;
  env: environment;
  version: string;
  ageRating: string;
  url: { backend: string; frontend: string };
} = {
  name: process.env['REACT_APP_NAME'] || 'priluvia_template',
  description: process.env['REACT_APP_DESCRIPTION'] || 'priluvia_description',
  env: (process.env['NODE_ENV'] as environment) || 'production',
  version: process.env['REACT_APP_VERSION'] || '0.0.1',
  faceBookAppID: process.env['REACT_APP_FACEBOOK_APP_ID'] || '',
  ageRating: process.env['REACT_APP_AGE_RATING'] || 'general',
  url: {
    backend: process.env['REACT_APP_BACKEND'] || 'http://localhost:8000',
    frontend: process.env['PUBLIC_URL'] || 'http://localhost:80'
  }
};
