/* eslint-disable @typescript-eslint/no-explicit-any */
// general react imports
import { AxiosResponse, Method } from 'axios';
import axios from 'axios';
import url from 'url-composer';
import Cookies from 'js-cookie';
import { appEnv } from 'app_env';
import { COOKIE_NAME } from './cookie';

type handleRequestType = {
  endpoint: string;
  id?: string;
  query?: any;
  params?: any;
  data?: any;
  headers?: any;
  handleResponse: (arg0: AxiosResponse) => any;
  method?: Method;
  disablePriluviaToken?: boolean;
};

// https://github.com/RasCarlito/url-composer
export const handleRequest = async ({
  endpoint,
  id,
  query,
  data,
  params,
  handleResponse,
  headers,
  method = 'GET',
  disablePriluviaToken = false
}: handleRequestType): Promise<AxiosResponse> => {
  const url_str = url.build({
    host: appEnv.url.backend,
    path: `:endpoint/${id ? ':id' : ''}`,
    params: { endpoint, id },
    query
  });

  const token = !disablePriluviaToken ? Cookies.get(COOKIE_NAME.TOKEN) : '';

  return axios({
    url: url_str,
    method: method,
    data,
    headers: {
      ...headers,
      ...(disablePriluviaToken ? {} : { 'X-CSRFToken': token })
    },
    params: params
  })
    .then((response) => handleResponse(response))
    .catch((error) => error);
};
