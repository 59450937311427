export const dict_pl = {
  'Local law: GB': 'Prawo: GB',
  EULA: 'Umowa EULA',
  'Privacy Policy': 'Polityka Prywatności',
  'Terms & Conditions': 'Regulamin',
  Next: 'Dalej',
  ACCEPT: 'Akceptuj',
  DECLINE: 'Odmów',
  Close: 'Zamknij',
  Search: 'Szukaj',
  Body: 'Ciało',
  General: 'Ogólne',
  'See more dates': 'Więcej dni',
  Summary: 'Podsumowanie',
  About: 'O nas',
  'API Docs': 'Opis API',
  Stats: 'Staty',
  'Priluvia NOW': 'Priluvia TERAZ',
  'Buy Premium Access': 'Kup Dostęp Premium',
  NOW: 'TERAZ',
  'Log in': 'Zaloguj',
  Mail: 'Wiadomości',
  Photos: 'Zdjęcia',
  'Assigned places': 'Przypisane miejsca',
  'Add new place': 'Dodaj nowe miejsce'
};
