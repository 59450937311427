// general react imports
import React from 'react';
import { InputAdornment, SxProps } from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import intersectionBy from 'lodash.intersectionby';

// project specific files
import { LegalIcon } from 'components/legal/legal_icon';
import { ModuleFlagImage } from 'components/module/module_flag_image';
import { DropdownRow } from 'components/dropdown/dropdown_row';
import { ModuleDataType, ModuleDataTypeArray } from 'types/data.d';
import { DropdownHeader } from './dropdown_header';
import { ModuleText } from 'components/text/module_text';
import { ModuleBox } from 'components/containers/module_box';

type DropdownTableType = {
  columns: Array<{ key: string; label: string }>;
  data: Array<object>;
  singleSelect?: boolean | undefined;
  handleClickCheck: (value: any) => void;
  selected_values: ModuleDataTypeArray;
  flag?: boolean | undefined;
  endAdornmentRow?: (index: number) => React.ReactNode | undefined;
  selectAll: () => void;
  deselectAll: () => void;
  hideHeader?: boolean | undefined;
  label: string;
  sx?: SxProps;
};

export const noDataRenderer = () => <ModuleText>No data available!</ModuleText>;

export const DropdownTable: React.FunctionComponent<DropdownTableType> = ({
  columns,
  data,
  singleSelect = false,
  handleClickCheck,
  selected_values,
  flag = false,
  endAdornmentRow,
  selectAll,
  deselectAll,
  hideHeader,
  label,
  sx
}) => {
  const getTableHeader = (): React.ReactNode | undefined =>
    hideHeader ? null : singleSelect ? (
      <DropdownHeader label={label} />
    ) : (
      <DropdownHeader
        label={label}
        checked={intersectionBy(selected_values, data, 'label').length > 0}
        onCheckboxClick={() => {
          const intersect = intersectionBy(selected_values, data, 'label');
          if (
            // some are selected so we expand the selection
            (intersect.length > 0 && intersect.length < data.length) ||
            // none are selected so we select everything
            intersect.length === 0
          ) {
            selectAll();
          } else {
            deselectAll();
          }
        }}
      />
    );

  const getTableRow = (index: number, option: ModuleDataType) => (
    <DropdownRow
      text={String(option.label)}
      singleSelect={singleSelect ? singleSelect : false}
      onClick={() => handleClickCheck([option])}
      checked={selected_values.some((obj) => obj.label === option.label)}
      startAdornment={
        flag && option['code'] ? (
          <InputAdornment position="start">
            <ModuleFlagImage countryCode={String(option['code'])} />
          </InputAdornment>
        ) : undefined
      }
      endAdornment={
        endAdornmentRow ? (
          endAdornmentRow(index)
        ) : option['legal'] ? (
          // @ts-ignore
          <LegalIcon choice={option['legal']} />
        ) : undefined
      }
    />
  );

  return (
    <ModuleBox sx={{ padding: '0 !important', height: '100%', ...sx }}>
      {getTableHeader()}
      {data.length === 0 ? noDataRenderer() : null}
      <TableVirtuoso
        style={{ height: '100%', width: '100%' }}
        columns={columns}
        // @ts-ignore
        data={data}
        overscan={{ main: 5, reverse: 5 }}
        itemContent={getTableRow}
        fixedHeaderContent={null}
      />
    </ModuleBox>
  );
};
