// general react imports
import { createSlice } from '@reduxjs/toolkit';
import { latLng } from 'leaflet';

// project specific imports
import { PlaceType } from 'types/place.d';

const initialState: PlaceType = {
  id: '1',
  name: 'Dom Grażyny',
  type: 'Apartment',
  short: 'AAAA-AAAA',
  time: [
    {
      label: '9:30'
    }
  ],
  coordinates: latLng(52.3724, 4.8977),
  verified: true,
  website: ['google.com'],
  activity: [{ label: 'handjob' }, { label: 'oral' }, { label: 'vaginal' }],
  age: [{ label: 20 }, { label: 27 }, { label: 35 }, { label: 41 }],
  body_type: [{ label: 'thicc' }, { label: 'regular' }],
  cup_size: [{ label: 'C' }, { label: 'D' }, { label: 'E' }, { label: 'F' }],
  ethnicity: [{ label: 'white' }, { label: 'asian' }],
  i_am: [{ label: 'female' }, { label: 'trans' }],
  looking_for: [{ label: 'male' }, { label: 'female' }],
  hair: [{ label: 'black' }, { label: 'red' }],
  height: [{ label: 174 }, { label: 181 }, { label: 190 }],
  language: [{ label: 'english' }, { label: 'polish' }],
  penis_size: [{ label: 13 }, { label: 15 }],
  photos: [
    {
      original: 'https://picsum.photos/id/1018/1000/600/',
      thumbnail: 'https://picsum.photos/id/1018/250/150/',
      is_title_shot: true
    },
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
      is_title_shot: false
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
      is_title_shot: false
    }
  ],
  people: []
};

export const placesSlice = createSlice({
  name: 'places',
  initialState: { payload: initialState },
  reducers: {
    set_places_content: (state, action) => {
      state.payload = action.payload.payload;
    }
  }
});

export const { set_places_content } = placesSlice.actions;
export const placesReducer = placesSlice.reducer;
